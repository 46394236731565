/**
* CSS for the slider component used in registration.
* @author A TSR Developer
* @device all
*/

/* Human verification - Slider */

.nojs .humanverify-slider {
    display: none;
}

.humanverify-slider .slider-container {
    margin-top: 12px;
}

.humanverify-slider .slider {
    background: #bbb8a4;
    box-shadow: inset 0 0 35px rgba(0,0,0,0.35);
    border: 1px solid #c34b20;
    border-radius: 4px;
    height: 39px;
    margin-bottom: 6px;
    overflow: hidden;
    padding: 2px;
    position: relative;
    width: 294px;
}

.humanverify-slider .slider .handle {
    background: url("//static.thestudentroom.co.uk/67eaa7acc9961/forum/images/register/slider-bg.png") no-repeat;
    cursor: e-resize;
    height: 39px;
    position: relative;
    width: 59px;
    z-index: 1;
}

.humanverify-slider .slider .handle img,
.humanverify-slider .slider .success-msg {
    transition: opacity 0.3s;
}

.humanverify-slider .slider .handle img {
    display: block;
    height: 23px;
    margin: 7px 12px;
    position: absolute;
        left: 0;
        top: 0;
    width: 34px;
}

.humanverify-slider .slider .handle .wait {
    filter: alpha(opacity = 0);
    height: 27px;
    margin: 5px 0 0 16px;
    opacity: 0;
    width: 27px;
}

.humanverify-slider .slider.success .handle {
    cursor: default;
}

.humanverify-slider .slider .success-msg,
.humanverify-slider .slider.success .handle .arrow {
    filter: alpha(opacity = 0);
    opacity: 0;
}

.humanverify-slider .slider.success .handle .wait,
.humanverify-slider .slider.success .success-msg {
    filter: alpha(opacity = 100);
    opacity: 1;
}

.humanverify-slider .slider .instruction,
.humanverify-slider .slider .success-msg {
    color: #62625d;
    cursor: default;
    font-size: 1.286em;
    line-height: 39px;
    pointer-events: none;
    position: absolute;
        top: 0;
        right: 0;
        left: 0;
    padding: 2px;
    text-align: center;
    text-transform: lowercase;
}

.humanverify-slider .slider .success-msg {
    z-index: 2;
}

.humanverify-slider .slider-alternate {
    color: #666;
    font-size: 0.857em;
    margin: 16px 0 0;
    width: 50%;
}

.humanverify-slider .slider-alternate .anchor {
    background: none;
    color: #21669c !important;
    font-size: 11px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    border: 0 !important;
}

.humanverify-slider .slider-alternate .anchor:hover {
    text-decoration: underline !important;
    cursor: pointer;
}
